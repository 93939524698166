import IProductCardModel from '../../ProductCard/Models/ProductCardModel.interface';
import KexLink from '../../Kex/KexLink';
import Image from '../../Atoms/Image/Image';
import { styled } from '../../stitches.config';
import CtaButton from '../../Atoms/Buttons/CtaButton';
import { UpdateCart } from '../../Pages/CartPage/Cart';
import { useState } from 'react';
import { useAppSettingsData } from '../../Shared/Providers/AppSettingsProvider';
import PageModelBase from '../../Shared/Models/PageModelBase.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import { useTranslationData } from '../../Shared/Providers/TranslationProvider';
import { useUserStateData } from '../../Shared/UserContextProvider/UserContextProvider';

type ProductCardType = {
  item: IProductCardModel;
  onCategoryPage?: boolean;
  showInStock?: boolean;
  isListView?: boolean;
};

function ProductCard({
  item: {
    image,
    name,
    code,
    url,
    brand,
    priceModel,
    mainCategory,
    backInStockDate,
    stockQuantity,
    inStock,
    isNetPrice,
    hideStockStatus,
    visaPaMT,
    visaPaZE,
  },
  onCategoryPage,
  showInStock = true,
  isListView,
  ...rest
}: ProductCardType) {
  const { languageRoute } = useAppSettingsData();
  const { channelId, pageId } = useCurrentPage<PageModelBase>();
  const { authenticated } = useUserStateData();

  const {
    'productPage/addToCart': addToCartLabel,
    'productPage/inStock': inStockLabel,
    'productPage/notInStock': notInStockLabel,
    'productPage/yourCampaignPrice': campaignPriceLabel,
    'productPage/yourPrice': yourPriceLabel,
    'productPage/listPrice': listPriceLabel,
    'productPage/exclVat': exclVatLabel,
    'product/artno': artnoLabel,
    'product/incomingStockCard': incomingStockLabel,
  } = useTranslationData();

  const litiumContext = JSON.stringify({
    currentPageSystemId: pageId,
    channelSystemId: channelId,
  });
  const [quantity, setQuantity] = useState<number>(1);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  function addToCart() {
    setIsAdding(true);
    UpdateCart(code, quantity, languageRoute, litiumContext)
      .then(() => setIsAdding(false))
      .catch(() => setIsAdding(false));
  }

  const onQuantityChange = (value: number) => {
    setQuantity(value);
  };

  const incoming = backInStockDate !== '';

  const parseBackInStock = (value: string) => {
    var splitStr = value.split('-');

    return `${splitStr[1]}, ${splitStr[0]}`;
  };

  return (
    <Root listView={isListView} {...rest} onCategoryPage={onCategoryPage}>
      <Card listView={isListView}>
        <StyledKexLink href={url} key={code} listView={isListView}>
          <ImageWrapper listView={isListView}>
            <ProductImage src={image ? image.url : ''} alt={name} />
          </ImageWrapper>
        </StyledKexLink>
        <ProductCardInfo href={url} listView={isListView} noUnderline>
          <Brand>{brand && <span>{brand}</span>}</Brand>
          <ProductName>{name}</ProductName>
          <Code>
            <span>
              {artnoLabel} {code}
            </span>
          </Code>
          <PriceWrapper>
            {authenticated && priceModel ? (
              <>
                {priceModel.campaignPrice?.priceFormatted ? (
                  <CampaignListPrice>
                    {campaignPriceLabel}{' '}
                    {priceModel.campaignPrice?.priceFormatted}{' '}
                    <span>{exclVatLabel}</span>
                  </CampaignListPrice>
                ) : (
                  <Price>
                    {yourPriceLabel} {priceModel.price?.priceFormatted}{' '}
                    <span>{exclVatLabel}</span>
                  </Price>
                )}
                {!isNetPrice && (
                  <ListPrice authenticated={true}>
                    {listPriceLabel} {priceModel.defaultPrice?.priceFormatted}{' '}
                    <span>{exclVatLabel}</span>
                  </ListPrice>
                )}
              </>
            ) : (
              !isNetPrice && (
                <ListPrice authenticated={false}>
                  {listPriceLabel} {priceModel?.defaultPrice?.priceFormatted}{' '}
                  <span>{exclVatLabel}</span>
                </ListPrice>
              )
            )}
          </PriceWrapper>
          {showInStock && !hideStockStatus && (
            <ListStockStatus>
              {inStock ? (
                <>
                  <StockStatusCircle inStock={inStock} />
                  <StockStatusText>
                    {stockQuantity} {inStockLabel}
                  </StockStatusText>
                </>
              ) : (
                <>
                  <StockStatusCircle incoming={incoming} />
                  <StockStatusText>
                    {backInStockDate
                      ? `${incomingStockLabel} ${parseBackInStock(
                          backInStockDate
                        )}`
                      : notInStockLabel}
                  </StockStatusText>
                </>
              )}
            </ListStockStatus>
          )}
        </ProductCardInfo>
      </Card>
    </Root>
  );
}

const Root = styled('div', {
  gridColumnEnd: 'span 12',
  '@mediaMinVerySmall': {
    gridColumnEnd: 'span 6',
  },
  '@mediaMinMedium': {
    gridColumnEnd: 'span 4',
  },
  '@mediaMinHuge': {
    gridColumnEnd: 'span 3',
  },
  variants: {
    listView: {
      true: {
        gridColumnEnd: 'span 1',
      },
    },
    onCategoryPage: {
      true: {
        '@mediaMinHuge': {
          gridColumnEnd: 'span 4',
        },
      },
    },
  },
});

const Card = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'transparent',
  alignItems: 'center',
  minW: 0,
  wh: '100%',
  variants: {
    listView: {
      true: {
        flexDirection: 'row',
        p: 4,
      },
    },
  },
});

const PriceWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  mt: '16px',
});

const ProductLabel = styled('div', {
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: '8px',
  left: '8px',
  py: '2px',
  px: '6px',
  backgroundColor: '#C85000',
  br: '4px',
  mb: '4px',
  textTransform: 'uppercase',
  color: '$white',
  fontSize: '7px',
  lineHeight: '16px',
  fontWeight: '$fw500',
  letterSpacing: '1px',
  '@mediaMinLarge': {
    mb: '8px',
    top: '16px',
    left: '16px',
    py: '8px',
    px: '16px',
    fontSize: '11px',
    fontWeight: '$fw600',
    lineHeight: '24px',
  },
});

const ListStockStatus = styled('div', {
  display: 'flex',
  alignItems: 'center',
  mt: '8px',
});

const StockStatusCircle = styled('div', {
  br: '100%',
  wh: '8px',
  backgroundColor: '$errorPrimary',
  top: '1px',
  variants: {
    inStock: {
      true: {
        backgroundColor: '$green',
        top: '-1px',
        position: 'relative',
      },
    },
    incoming: {
      true: {
        backgroundColor: '#7B7978',
      },
    },
  },
  mr: 2,
});

const StockStatusText = styled('span', {
  color: '$black',
  fontSize: '8px',
  fontWeight: '$fw400',
  lineHeight: '16px',
  letterSpacing: '1px',
  '@mediaMinLarge': {
    fontSize: '12px',
    lineHeight: '20px',
  },
});

const Brand = styled('div', {
  fs: 4,
  fontWeight: '$fw400',
  textTransform: 'uppercase',
  '@mediaMinLarge': {
    fs: 5,
  },
});

const Code = styled('div', {
  fs: 4,
  fontWeight: '$fw400',
  '@mediaMinLarge': {
    fs: 5,
  },
});

const Divider = styled('span', {
  mx: 1,
});

const AddToCart = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  p: 0,
  pt: 0,
  w: '100%',
  gap: '20px',
  variants: {
    listView: {
      true: {
        p: 0,
        w: 45,
      },
    },
  },
});

const AddToCartButton = styled(CtaButton, { flexBasis: '50%', h: 9 });

const ImageWrapper = styled('div', {
  wh: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  variants: {
    listView: {
      true: {
        maxW: 16,
        '& img': {
          h: 18,
        },
      },
    },
  },
});

const ProductCardInfo = styled(KexLink, {
  py: '16px',
  display: 'flex',
  alignItems: 'flex-start',
  flexDirection: 'column',
  wh: '100%',
  textDecoration: 'none',
  variants: {
    listView: {
      true: {
        p: 0,
        pl: 6,
        '& div:nth-child(3)': {
          justifySelf: 'end',
        },
      },
    },
  },
});

const ProductImage = styled(Image, {
  br: 2,
  h: '136px',
  objectFit: 'contain',
  '@mediaMinLarge': {
    h: '276px',
  },
});

const ProductName = styled('span', {
  fontSize: '10px',
  fontWeight: '$fw600',
  lineHeight: '16px',
  letterSpacing: '1px',
  '@mediaMinLarge': {
    fontSize: '14px',
    lineHeight: '23px',
  },
});

const Price = styled('p', {
  fontWeight: '$fw600',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  m: 0,
  mb: '8px',
  variants: {
    gridView: {
      true: {
        mt: 'auto',
      },
    },
  },
});

const ListPrice = styled('p', {
  fontWeight: '$fw400',
  fontSize: '10px',
  lineHeight: '16px',
  letterSpacing: '1px',
  m: 0,
  mb: '8px',
  variants: {
    authenticated: {
      false: {
        fontWeight: '$fw600',
        fontSize: '12px',
        lineHeight: '20px',
        letterSpacing: '1px',
      },
    },
    gridView: {
      true: {
        mt: 'auto',
      },
    },
  },
});

const CampaignListPrice = styled('p', {
  color: '#C85000',
  fontWeight: '$fw400',
  fontSize: '12px',
  lineHeight: '20px',
  letterSpacing: '1px',
  m: 0,
  variants: {
    gridView: {
      true: {
        mt: 'auto',
      },
    },
  },
});

const StyledKexLink = styled(KexLink, {
  display: 'flex',
  wh: '100%',
  variants: {
    listView: {
      true: {
        maxW: 16,
      },
    },
  },
});

export default ProductCard;
